<template>
  <form-group class="my-8">
    <form-label id="attempts">Question Attempts</form-label>
    <div>
      <UISwitch
        name="Override Attempts"
        v-model="override"
        :disabled="overrideDisabled"
      >
        Override Attempts
      </UISwitch>
    </div>
    <div class="pt-6" v-if="override">
      <form-group class="flex">
        <number-input
          id="overrideAttemptsDragDrop"
          class="w-14"
          :modelValue="model.dragDrop"
          :disabled="overrideDisabled"
          @input="
            (value: number) =>
              emit('update:modelValue', { ...model, dragDrop: value })
          "
          label="Total number of attempts for all drag & drop questions"
        />
        <label
          for="overrideAttemptsDragDrop"
          class="font-normal pl-4 leading-[2.4]"
          >Total number of attempts for all
          <strong>drag &amp; drop</strong> questions</label
        >
      </form-group>
      <form-group class="flex">
        <number-input
          id="overrideAttemptsMultipleChoice"
          class="w-14"
          :modelValue="model.multipleChoice"
          :disabled="overrideDisabled"
          @input="
            (value: number) =>
              emit('update:modelValue', { ...model, multipleChoice: value })
          "
          label="Total number of attempts for all multiple choice questions"
        />
        <label
          for="overrideAttemptsMultipleChoice"
          class="font-normal pl-4 leading-[2.4]"
          >Total number of attempts for all
          <strong>multiple choice</strong> questions</label
        >
      </form-group>
      <form-group class="flex">
        <number-input
          id="overrideAttemptsNumerical"
          class="w-14"
          :modelValue="model.numerical"
          :disabled="overrideDisabled"
          @input="
            (value: number) =>
              emit('update:modelValue', { ...model, numerical: value })
          "
          label="Total number of attempts for all numerical questions"
        />
        <label
          for="overrideAttemptsNumerical"
          class="font-normal pl-4 leading-[2.4]"
          >Total number of attempts for all
          <strong>numerical</strong> questions</label
        >
      </form-group>
    </div>
    <div class="w-2/3 mt-3" v-if="overrideDisabled">
      Unless the feedback is shown after submitting each question, the activity
      does not support custom attempt limits
    </div>
  </form-group>
</template>

<script setup lang="ts">
import { AssignmentsResponse, OverrideAttempts } from '@pi/api-types'
import { computed, ref, watch } from 'vue'
import { UISwitch } from '@pi/design'
import { AssignmentStudentFeedbackTimingValue } from '@pi/types'

interface AssignmentAttemptProps {
  assignment?: AssignmentsResponse
}
const model = defineModel<OverrideAttempts>('attemptsOverride', {
  default: {
    dragDrop: undefined,
    multipleChoice: undefined,
    numerical: undefined
  }
})
const props = defineProps<AssignmentAttemptProps>()
const emit = defineEmits(['update:modelValue'])

const override = ref<boolean>(
  Boolean(
    model.value.dragDrop || model.value.multipleChoice || model.value.numerical
  )
)

const overrideDisabled = computed(() => {
  return (
    props.assignment?.studentFeedbackTiming ===
    AssignmentStudentFeedbackTimingValue.AfterAssignmentSubmit
  )
})

watch(override, () => {
  if (!override.value) {
    emit('update:modelValue', undefined)
  }
})

watch(overrideDisabled, () => {
  if (overrideDisabled.value) {
    override.value = false
    emit('update:modelValue', undefined)
  }
})
</script>
