<template>
  <async-form @submit="onSubmit" persist>
    <div
      class="shadow-md border border-solid border-ui-gray-200 rounded-md p-6 my-10"
    >
      <div class="font-bold text-black text-3xl mb-4">Assignment Dates</div>

      <div
        class="grid gap-6 grid-cols-2 md:grid-cols-2 lg:grid-cols-2 max-w-fit"
      >
        <form-group class="max-w-s">
          <form-label for="start-date">Start Date</form-label>
          <date-input
            id="start-date"
            v-model="startDate"
            time
            label="start date"
            :max-date="assignment.endDate"
            help-text="Students can work on the assignment after this date."
          />
        </form-group>
        <form-group class="max-w-s">
          <form-label for="end-date">Due Date</form-label>
          <date-input
            id="end-date"
            v-model="endDate"
            :disabled="!assignment.startDate"
            time
            :rules="{ after: assignment.startDate }"
            label="end date"
            :min-date="assignment.startDate"
            help-text="Students cannot work on the assignment after this date, and all work will be automatically submitted."
          />
        </form-group>
      </div>
      <form-group>
        <UISwitch
          v-model="isCustomVisibleDatesEnabled"
          @change="onCustomVisibilityDateChange"
          name="Enable Custom Assignment Visibility Dates"
          >Enable Custom Assignment Visibility Dates</UISwitch
        >
      </form-group>
      <div
        class="grid gap-6 grid-cols-2 md:grid-cols-2 lg:grid-cols-2 max-w-fit"
        v-if="isCustomVisibleDatesEnabled"
      >
        <form-group class="max-w-s">
          <form-label for="first-visible-date"> First Visible Date </form-label>
          <date-input
            id="first-visible-date"
            v-model="assignment.firstVisibleDate"
            time
            label="first visible date"
            :rules="{
              required: !!assignment.startDate,
              before: assignment.startDate
            }"
            :max-date="assignment.startDate ?? assignment.lastVisibleDate"
            help-text="After this date, students can see this assignment in their list, but cannot open it."
          />
        </form-group>
        <form-group class="max-w-s justify-self-end">
          <form-label for="last-visible-date"> Last Visible Date </form-label>
          <date-input
            id="last-visible-date"
            v-model="assignment.lastVisibleDate"
            :disabled="!assignment.endDate"
            time
            label="last visible date"
            :rules="{
              after: assignment.endDate
            }"
            :min-date="assignment.endDate"
            help-text="Students cannot see the assignment after this date."
          />
        </form-group>
      </div>
    </div>
    <div
      class="shadow-md border border-solid border-ui-gray-200 rounded-md p-6 my-10"
      :collapsed="assignment.assignedTo === 'everyone'"
    >
      <div class="font-bold text-black text-3xl mb-4">Assign To</div>
      <div class="assignment-settings__groups">
        <form-group>
          <div>
            <selector-input
              v-model="assignment.assignedTo"
              aria-labelledby="assign-to"
              label="assign-to"
              class="selector-input--horizontal"
            >
              <selector-option value="everyone" title="Everybody" disabled />
              <selector-option
                value="individuals"
                title="Individuals"
                disabled
              />
              <selector-option value="groups" title="Co-Lab Groups" disabled />
            </selector-input>
          </div>
        </form-group>
        <form-group>
          <form-button
            v-if="canAssignStudents"
            class="edit-group-btn"
            secondary
            @click="() => (isAssigningStudents = true)"
          >
            Edit Assigned
            {{ assignment.assignedTo === 'groups' ? 'Groups' : 'Individuals' }}
          </form-button>
          <form-button
            v-if="isAssigningStudents"
            class="edit-group-btn"
            secondary
            @click="revertAssignedStudents"
          >
            Revert Changes
          </form-button>
        </form-group>
        <template v-if="assignment.assignedTo === 'groups'">
          <p class="text-warning">
            All work stays with the existing group. If you move students to a
            different group, they will have access to the work done by the new
            group, not their old group. If all the students in a group are
            removed, this work will be archived; you will need to contact Pivot
            Support to get access to the work in this empty group.
          </p>

          <activity-groups
            ref="activityGroups"
            :roster="roster"
            :groups="groups"
            :disabled="!isAssigningStudents"
            @change="updateGroups"
          />
        </template>
        <activity-subsets
          v-else-if="assignment.assignedTo === 'individuals'"
          :modelValue="individuals"
          :roster="roster"
          :disabled="!isAssigningStudents"
          @update:modelValue="updateIndividuals"
        />
      </div>
    </div>
    <div
      class="shadow-md border border-solid border-ui-gray-200 rounded-md p-6 my-10"
    >
      <div class="font-bold text-black text-3xl mb-4">Settings</div>
      <div class="flex">
        <div class="assignment-settings__points w-1/2">
          <form-group>
            <assignment-point-total-override
              :assignment="assignment"
              v-model:totalPointValue="assignment.totalPointValue"
              v-model:ignorePoints="assignment.ignoreInstructorGradedPoints"
            />
            <div class="assignment-settings__point-splits">
              <div>
                <form-label> Auto Graded </form-label>
                <div>{{ activityInfo.autogradePoints }} Points</div>
                <div>({{ activityInfo.autogradeQuestions }} Questions)</div>
              </div>

              <div>
                <form-label> Instructor Graded </form-label>
                <div>{{ activityInfo.instructorPoints }} Points</div>
                <div>({{ activityInfo.instructorQuestions }} Questions)</div>
              </div>
            </div>
          </form-group>

          <assignment-attempts-override
            v-if="showAttemptsOverride"
            :assignment="assignment"
            v-model:attemptsOverride="assignment.attemptsOverride"
            @update:modelValue="overrideAttemptsChanged"
          />
        </div>
        <div class="assignment-settings__randomize w-1/2">
          <assignment-feedback-controls
            v-if="controlsEnabled"
            v-model:noHints="assignment.noHints"
            v-model:studentFeedbackTiming="assignment.studentFeedbackTiming"
          />
          <form-group>
            <form-label>Randomization</form-label>
            <UISwitch
              :v-model="isRandomized"
              disabled
              name="Randomize Questions"
              >Randomize Questions</UISwitch
            >
          </form-group>
          <router-link
            :to="{
              name: 'preview_assignment',
              params: { id: assignment.id }
            }"
            >{{ activityInfo.randomizedQuestions }} Questions</router-link
          >
          will have randomized answers which are different for each student.
        </div>
      </div>
    </div>
    <form-group class="button-group">
      <submit-button class="pull-left">
        <template #default>Save</template>
        <template #submitting>Updating</template>
        <template #submitted>Updated</template>
      </submit-button>
    </form-group>
  </async-form>
</template>

<script setup>
import { computed, inject, watch, ref, toRef } from 'vue'
import { isEqual, isBefore, isAfter } from 'date-fns'
import TotalPossiblePointsModal from 'src/shared/components/modals/TotalPossiblePointsModal'
import assignmentPointTotalOverride from './AssignmentPointTotalOverride.vue'
import AssignmentAttemptsOverride from './AssignmentAttemptsOverride.vue'
import AssignmentFeedbackControls from './AssignmentFeedbackControls.vue'
import ActivityGroups from './ActivityGroups'
import ActivitySubsets from './ActivitySubsets'
import { useFlash } from 'src/shared/hooks/flash'
import client from 'src/shared/api-client'
import { useStore } from 'vuex'
import { UISwitch } from '@pi/design'
import { getFlattenedComponents } from '../utils'

const $modal = inject('$modal')
const flash = useFlash()
const store = useStore()
const emit = defineEmits(['refresh'])
const props = defineProps({
  assignment: {
    type: Object,
    required: true
  },
  responses: {
    type: Array,
    required: true
  },
  activity: {
    type: Object,
    required: true
  },
  klass: {
    type: Object,
    required: true
  }
})

const controlsEnabled = computed(() =>
  store.getters['features/isEnabled']('assessmentMode')
)
const showAttemptsOverride = computed(() =>
  store.getters['features/isEnabled']('attempts')
)

function cloneAssignment(assignment) {
  return {
    ...assignment,
    startDate: assignment.startDate
      ? new Date(assignment.startDate)
      : undefined,
    endDate: assignment.endDate ? new Date(assignment.endDate) : undefined,
    firstVisibleDate: assignment.firstVisibleDate
      ? new Date(assignment.firstVisibleDate)
      : undefined,
    lastVisibleDate: assignment.lastVisibleDate
      ? new Date(assignment.lastVisibleDate)
      : undefined
  }
}
const assignment = ref(cloneAssignment(props.assignment))
watch(toRef(props, 'assignment'), _assignment => {
  assignment.value = cloneAssignment(_assignment)
})

const isCustomVisibleDatesEnabled = ref(false)

const isCustomVisibleDatesInitiallyEnabled = computed(() => {
  return (
    Date.parse(assignment.value.firstVisibleDate) <
      Date.parse(assignment.value.startDate) ||
    assignment.value.lastVisibleDate !== undefined
  )
})

watch(
  () => [
    assignment.value.firstVisibleDate,
    assignment.value.startDate,
    assignment.value.lastVisibleDate
  ],
  () => {
    isCustomVisibleDatesEnabled.value =
      isCustomVisibleDatesInitiallyEnabled.value
  },
  { immediate: true }
)

function onCustomVisibilityDateChange() {
  if (isCustomVisibleDatesEnabled.value === false) {
    assignment.value.firstVisibleDate = assignment.value.startDate
    assignment.value.lastVisibleDate = undefined
  }
}

const isRandomized = computed(() => !assignment.value.disableRandomization)

const isPointTotalOverride = ref(false)

const roster = computed(() =>
  props.klass.roster.map(s => ({
    ...s.student,
    name: `${s.student.firstName} ${s.student.lastName}`
  }))
)

const activityInfo = computed(() =>
  getFlattenedComponents(props.activity).reduce(
    (info, component) => {
      if (component.autograde) {
        info.autogradePoints += component.pointValue ?? 0
        info.autogradeQuestions += 1
      } else if (component.componentType.includes('Question')) {
        info.instructorPoints += component.pointValue ?? 0
        info.instructorQuestions += 1
      }
      if (component.isRandomized) {
        info.randomizedQuestions += 1
      }
      return info
    },
    {
      instructorPoints: 0,
      instructorQuestions: 0,
      autogradePoints: 0,
      autogradeQuestions: 0,
      randomizedQuestions: 0
    }
  )
)
const totalPoints = computed(() => {
  return assignment.value.totalPointValue
    ? assignment.value.totalPointValue
    : assignment.value.ignoreInstructorGradedPoints
      ? activityInfo.value.autogradePoints
      : props.activity.totalPointValue
})

const startDate = computed({
  get: () => assignment.value.startDate,
  set: startDate => {
    const oldStartDate = assignment.value.startDate
    assignment.value.startDate = startDate

    if (startDate) {
      const { firstVisibleDate } = assignment.value
      if (
        isEqual(firstVisibleDate, oldStartDate) ||
        isBefore(startDate, firstVisibleDate)
      ) {
        assignment.value.firstVisibleDate = startDate
      }
    } else {
      assignment.value.firstVisibleDate = undefined
    }
  }
})
const endDate = computed({
  get: () => assignment.value.endDate,
  set: endDate => {
    assignment.value.endDate = endDate

    if (endDate) {
      const { lastVisibleDate } = assignment.value
      if (lastVisibleDate && isAfter(endDate, lastVisibleDate)) {
        assignment.value.lastVisibleDate = endDate
      }
    } else {
      assignment.value.lastVisibleDate = undefined
    }
  }
})

const hasGroupEdits = ref(false)
const groups = ref([])
function revertGroupEdits() {
  hasGroupEdits.value = false
  groups.value = props.responses.map(response => ({
    ...response,
    name: response.groupName
  }))
}
function updateGroups(_groups) {
  hasGroupEdits.value = true
  groups.value = _groups
}

const hasIndividualEdits = ref(false)
const individuals = ref([])
function revertIndividualEdits() {
  hasIndividualEdits.value = false
  individuals.value = props.responses.map(r => r.studentId)
}
function updateIndividuals(_individuals) {
  hasIndividualEdits.value = true
  individuals.value = _individuals
}

watch(
  () => ({ assignment: assignment.value, responses: props.responses }),
  ({ assignment }) => {
    individuals.value = []
    hasIndividualEdits.value = false
    groups.value = []
    hasGroupEdits.value = false
    isPointTotalOverride.value = false

    switch (assignment.assignedTo) {
      case 'groups':
        revertGroupEdits()
        break
      case 'individuals':
        revertIndividualEdits()
        break
    }
  },
  { immediate: true }
)

const isAssigningStudents = ref(false)
const canAssignStudents = computed(
  () =>
    ['groups', 'individuals'].includes(assignment.value.assignedTo) &&
    !isAssigningStudents.value
)
function revertAssignedStudents() {
  isAssigningStudents.value = false
  if (hasGroupEdits.value) {
    revertGroupEdits()
  }
  if (hasIndividualEdits.value) {
    revertIndividualEdits()
  }
}

async function onSubmit(e) {
  try {
    await client.assignments.update({
      assignmentId: assignment.value.id,
      startDate:
        assignment.value.startDate === undefined
          ? null
          : assignment.value.startDate,
      endDate:
        assignment.value.endDate === undefined
          ? null
          : assignment.value.endDate,
      firstVisibleDate:
        assignment.value.firstVisibleDate === undefined ||
        !isCustomVisibleDatesEnabled.value
          ? assignment.value.startDate
          : assignment.value.firstVisibleDate,
      lastVisibleDate:
        assignment.value.lastVisibleDate === undefined ||
        !isCustomVisibleDatesEnabled.value
          ? null
          : assignment.value.lastVisibleDate,
      attemptsOverride:
        assignment.value.attemptsOverride === undefined
          ? null
          : assignment.value.attemptsOverride,
      totalPointValue:
        assignment.value.totalPointValue === undefined
          ? null
          : assignment.value.totalPointValue,
      studentFeedbackTiming: assignment.value.studentFeedbackTiming,
      scoreVisibility: assignment.value.scoreVisibility,
      feedbackVisibility: assignment.value.feedbackVisibility,
      noHints: assignment.value.noHints,
      ignoreInstructorGradedPoints:
        assignment.value.ignoreInstructorGradedPoints === undefined
          ? null
          : assignment.value.ignoreInstructorGradedPoints
    })

    if (hasGroupEdits.value) {
      await client.assignments.updateResponses({
        assignmentId: assignment.value.id,
        type: assignment.value.assignedTo,
        groups: groups.value.map(g => {
          return {
            id: g.id,
            name: g.groupName || g.name,
            students: g.students.map(s => s._id)
          }
        })
      })
    } else if (hasIndividualEdits.value) {
      await client.assignments.updateResponses({
        assignmentId: assignment.value.id,
        type: assignment.value.assignedTo,
        students: individuals.value
      })
    }

    isAssigningStudents.value = false
    flash.success('Updated assignment successfully.')
    e.done()
    emit('refresh')
  } catch (error) {
    e.done(false)
    if (error.body?.operations) {
      error.body.operations.forEach(operation => {
        flash.error(`${operation.id}: ${operation.error}`)
      })
    } else {
      throw error
    }
  }
}
async function changeTotalPoints() {
  const { status, data } = await $modal.show(TotalPossiblePointsModal, {
    autoGradedPoints: activityInfo.value.autogradePoints,
    instructorGradedPoints: activityInfo.value.instructorPoints,
    totalPossiblePoints: totalPoints.value,
    ignoreInstructorGradedPoints: assignment.value.ignoreInstructorGradedPoints
  })
  if (status === 'ok') {
    assignment.value.totalPointValue = data
    await client.assignments.update({
      assignmentId: assignment.value.id,
      totalPointValue: data || props.activity.totalPointValue
    })

    flash.success(`Total possible points successfully updated to ${data}.`)
    emit('refresh')
  }
}

const overrideAttemptsChanged = val => {
  assignment.value = {
    ...assignment.value,
    attemptsOverride: val
  }
}

const feedbackValuesChanged = values => {
  assignment.value = {
    ...assignment.value,
    ...values
  }
}
</script>

<style lang="scss">
.assignment-settings__form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-evenly;
}
.assignment-settings__panel {
  box-shadow: 0px 10px 27px -11px rgba(0, 0, 0, 0.71) !important;
}

.assignment-settings__points {
  grid-area: points;
}

.assignment-settings__total-points {
  font-weight: bold;
  margin-right: 16px;
  display: inline-block;
}

.assignment-settings__point-splits {
  display: flex;

  & > :not(:first-child) {
    margin-left: 32px;
  }
}

.assignment-settings__groups {
  grid-area: assign;
}
</style>
