<template>
  <div class="new-assignment-card">
    <div class="new-assignment-card__header">
      <h4>Activity: {{ name }}</h4>
      <button
        v-if="!lastEntry"
        @click.prevent="emit('remove')"
        class="new-assignment-card__close"
        type="button"
        aria-label="close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="date-group">
      <div class="date-group__inner">
        <form-group>
          <form-label> Start Date </form-label>
          <date-input
            v-model="startDate"
            time
            label="start date"
            help-text="Students can work on the assignment after this date."
          />
        </form-group>
        <form-group>
          <form-label> Due Date </form-label>
          <date-input
            v-model="endDate"
            :disabled="!startDate"
            :rules="{ after: startDate }"
            time
            label="due date"
            :min-date="startDate"
            help-text="Students cannot work on the assignment after this date, and all work will be automatically submitted."
          />
        </form-group>
      </div>

      <form-group>
        <UISwitch
          v-model="isCustomVisibleDatesEnabled"
          @change="onCustomVisibilityDateChange"
          name="Enable Custom Assignment Visibility Dates"
          >Enable Custom Assignment Visibility Dates</UISwitch
        >
      </form-group>
      <div
        class="grid gap-6 grid-cols-2 md:grid-cols-2 lg:grid-cols-2 max-w-fit"
        v-if="isCustomVisibleDatesEnabled"
      >
        <form-group class="max-w-s">
          <form-label for="first-visible-date"> First Visible Date </form-label>
          <date-input
            id="first-visible-date"
            v-model="firstVisibleDate"
            time
            label="first visible date"
            :rules="{
              required: !!startDate,
              before: startDate
            }"
            :max-date="startDate ?? lastVisibleDate"
            help-text="After this date, students can see this assignment in their list, but cannot open it."
          />
        </form-group>
        <form-group class="max-w-s justify-self-end">
          <form-label for="last-visible-date"> Last Visible Date </form-label>
          <date-input
            id="last-visible-date"
            v-model="lastVisibleDate"
            :disabled="!endDate"
            time
            label="last visible date"
            :rules="{
              after: endDate
            }"
            :min-date="endDate"
            help-text="Students cannot see the assignment after this date."
          />
        </form-group>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, watch, Ref, ref } from 'vue'
import { UISwitch } from '@pi/design'
interface Dates {
  startDate: Date | undefined
  endDate: Date | undefined
  firstVisibleDate: Date | undefined
  lastVisibleDate: Date | undefined
}

const props = withDefaults(
  defineProps<{
    dates: Dates
    name?: string
    lastEntry?: boolean
  }>(),
  {
    name: 'N/A',
    lastEntry: true
  }
)

const emit = defineEmits(['update:dates', 'remove'])

const startDate: Ref<Date | undefined> = computed({
  get: () => props.dates.startDate,
  set: (value: Date | undefined) =>
    emit('update:dates', { ...props.dates, startDate: value })
})

const endDate: Ref<Date | undefined> = computed({
  get: () => props.dates.endDate,
  set: (value: Date | undefined) =>
    emit('update:dates', { ...props.dates, endDate: value })
})

const firstVisibleDate: Ref<Date | undefined> = computed({
  get: () => props.dates.firstVisibleDate,
  set: (value: Date | undefined) =>
    emit('update:dates', { ...props.dates, firstVisibleDate: value })
})

const lastVisibleDate: Ref<Date | undefined> = computed({
  get: () => props.dates.lastVisibleDate,
  set: (value: Date | undefined) =>
    emit('update:dates', { ...props.dates, lastVisibleDate: value })
})

function onCustomVisibilityDateChange() {
  if (isCustomVisibleDatesEnabled.value === false) {
    firstVisibleDate.value = startDate.value
    lastVisibleDate.value = undefined
  }
}

const isCustomVisibleDatesEnabled = ref(false)

watch(
  () => startDate.value,
  (newStartDate: Date | undefined, oldStartDate: Date | undefined) => {
    if (
      firstVisibleDate.value === oldStartDate ||
      (newStartDate &&
        firstVisibleDate.value &&
        newStartDate < firstVisibleDate.value)
    ) {
      firstVisibleDate.value = newStartDate
    }
  }
)

watch(
  () => endDate.value,
  (newEndDate: Date | undefined) => {
    if (
      newEndDate &&
      lastVisibleDate.value &&
      newEndDate > lastVisibleDate.value
    ) {
      lastVisibleDate.value = newEndDate
    }

    if (!newEndDate) {
      lastVisibleDate.value = undefined
    }
  }
)
</script>
<style lang="scss">
.date-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
}

.date-group__inner {
  display: flex;
  gap: 24px;
}

@media (max-width: 1000px) {
  .date-group__inner {
    width: 100%;
    & > * {
      width: 50%;
    }
  }
}

.new-assignment-card {
  padding-left: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}

.new-assignment-card__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.new-assignment-card__header h4 {
  margin-top: 25px;
}
.new-assignment-card__close {
  color: $color-error;
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
  background-color: transparent;
  border: none;
  &:focus {
    outline: $teal solid 2px;
  }
}
</style>
